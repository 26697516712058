
import Vue from 'vue';

export default Vue.extend({
    name: 'Autocounter',
        props: {
            start: {
                type: Boolean,
                default: false
            },
            stop: {
                type: Number | String,
                default: 100
            },
            cssStyle:{
                type:String,
                default: ''
            },
            suffix:{
                type:String,
                default: ''
            },
            prefix: {
                type:String,
                default: ''
            },
            initial: {
                type: Number,
                default: 0
            },
            duration: {
                type: Number,
                default: 1
            }
        },
        created() {
            this.defaultStart = this.initial? this.initial : 0;

        },
        methods: {
            updated() {
                this.result = this.stop.toString().includes('.') ? (this.result++ + this.duration).toFixed(1) : this.defaultStart+= this.duration;
                if (this.result >= this.stop) {
                    this.result = this.stop;
                    clearInterval(this.operation);
                }
            }
        },
        data () {
            return {
               operation: null,
               result: 0,
               defaultStart: 0
            };
        },
        watch: {
            start(newVal) {
                if(newVal) {
                   this.operation = setInterval(this.updated)
                }
            }
        }
    });
