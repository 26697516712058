
    import Vue from 'vue';
    import {mapActions, mapGetters} from 'vuex'
    import Autocounter from '~/components/Autocounter.vue';

    export default Vue.extend({
        name: 'IndexPage',
        components: { Autocounter },
        data() {
          return {
            isOpen: false as Boolean,
            onPosition: false as Boolean,
            offsetTop: null,
            isTrigged: false as Boolean,
            envType: this.$config.env as String
          }
        },
        head() {
          return {
            script: [
              {
                hid: 'TrustPilotWidget',
                src: '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
                async: true,
                defer: true,
                body: true
              }
            ],
          }
        },
        computed: {
            ...mapGetters({
                reviewCount:'reviewCount',
                numServed:'numServed'
            }),
          bannerImageSrc() {
            return this.envType === 'production' ? 'home/HomepageProd.webp' : 'home/HomepageStg.webp';
          },
          bannerFallbackSrc() {
            return this.envType === 'production' ? 'home/HomepageProd.jpg' : 'home/HomepageStg.jpg';
          }
        },
        mounted () {
            window.addEventListener('scroll', this.onScroll);
            // Load the Trustpilot widget when the component is mounted (page is loaded)
            const a = document?.getElementById("TrustPilotWidget");
            if (a){
              window?.Trustpilot?.loadFromElement(a);
            }
            this.getReviewCount();
        },
        methods: {
            ...mapActions({
                getReviewCount: 'getReviewCount',
                getNumServed: 'getNumServed',
            }),
            onScroll () {
                const elemRef:any = this.$refs.blueBarContainer;
                const pageTop = window.scrollX;
                const pageBottom = pageTop + window.innerHeight;
                if(elemRef) {
                    if(pageBottom >= elemRef.getBoundingClientRect().top && !this.isTrigged) {
                        this.onPosition = !this.onPosition;
                        this.isTrigged = true;
                    }
                }
            }
        },
        fetchOnServer: false,
    })
